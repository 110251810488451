export const faq = [
    {
       id: "collapseOne",
       question: "How much does it cost to build an app?",
       ans: "While every app is different, prices usually start at $10k. We can reuse our prebuilt components to save costs. Talk to us about partnerships or monthly payment plan."
    },
    {
       id: "collapseOne",
       question: "Can I provide my own designs?",
       ans: "Sure, we are open to work with any designs you might already have."
    },
    {
      id: "collapseOne",
      question: "Can my app integrate with a 3rd party API?",
      ans: "Sure, in fact all the apps we have built integrate with multiple APIs."
   },
    {
      id: "collapseOne",
      question: "What is the benefit of a cross platform app?",
      ans: "React Native has been a popular and mature cross platform framework. It has the benefit of building once and deploying to both native iOS and Android, saving time and costs."
   },
    {
       id: "collapseOne",
       question: "Will I get to test my app before releasing to the public?",
       ans: "Sure, user acceptance test is a part of our process. You will be able to test the app on your device and provide feedback before we submit it for a public release."
    },
    {
       id: "collapseOne",
       question: "How will you manage the project and status updates?",
       ans: "The project will be divided into smaller tickets using Azure DevOps/Jira/Trello. You will have constant access to the tickets so yo can see their status. In addition we will send weekly updates."
    },
 ]